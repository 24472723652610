import { Card, Col, Icon, Row } from 'antd';
import React, { FC } from 'react';
import { formatPhone, formatTitleCase } from '../../../../../helpers';
import * as ptFormat from '../../../../../helpers/patients.helper';
import { ReferralData } from '../../Referral/GetReferralQuery';
import ShowPatientDetails from './ShowPatientDetails';

interface SearchHeaderPatientCardProps {
  referral: ReferralData;
}

const SearchHeaderPatientCard: FC<SearchHeaderPatientCardProps> = ({
  referral,
}: SearchHeaderPatientCardProps): JSX.Element => {
  const { procedure, patient, coverage, patientIsPreAuthorized } = referral;
  const title = ptFormat.formatName(patient);

  let referredBy = null;
  let referredTo = null;
  let referralSenderReceiverInfo = '';

  const preferredTag = referral.tags?.find(item => item && item.key === 'preferred_provider');

  if (referral.provider) {
    referredBy = `Referred by: ${referral.provider.name}`;
  }

  if (referral.appointment?.profile?.displayName) {
    referredTo = `Referred to: ${referral.appointment.profile.displayName}`;
  } else if (referral.patientReferral?.profile?.displayName) {
    referredTo = `Referred to: ${referral.patientReferral.profile.displayName}`;
  } else if (referral.profileReferral?.profile?.displayName) {
    referredTo = `Referred to: ${referral.profileReferral.profile.displayName}`;
  } else if (preferredTag) {
    referredTo = `Referred to: ${preferredTag.value}`;
  }

  if (referredBy && referredTo) {
    referralSenderReceiverInfo = referredBy.concat(' / ', referredTo);
  } else if (referredBy) {
    referralSenderReceiverInfo = referredBy;
  } else if (referredTo) {
    referralSenderReceiverInfo = referredTo;
  }

  return (
    <Card
      title={
        <>
          <b>{title}</b>&nbsp;&nbsp;({procedure.specialty.name} — {procedure.name} Referral)
          <br />
          {referralSenderReceiverInfo}
        </>
      }
      size="small"
      extra={
        <ShowPatientDetails patient={patient} coverage={coverage} patientIsPreAuthorized={patientIsPreAuthorized} />
      }
    >
      <Row type="flex" justify="center" gutter={12}>
        <Col span={8}>
          <ul>
            <FactIcon type="idcard" text={`MRN: ${patient.identifier}`} />
            <FactIcon type="calendar" text={ptFormat.formatDob(patient.dob)} />
            <SexFactIcon sex={patient.sex} />
          </ul>
        </Col>
        <Col span={8}>
          <ul>
            <FactIcon type="phone" text={formatPhone(patient.phone) || 'Unknown Phone'} />
            <FactIcon type="mail" text={patient.email || 'Unknown Email'} />
          </ul>
        </Col>
        <Col span={8}>
          <PatientAddress
            address1={formatTitleCase(patient.address1)}
            address2={formatTitleCase(patient.address2)}
            city={formatTitleCase(patient.city)}
            state={patient.state}
            postalCode={patient.postalCode}
          />
        </Col>
      </Row>
    </Card>
  );
};

const SexFactIcon: FC<{ sex: string | null | null }> = ({ sex }: any): JSX.Element => {
  let type = '';
  let text = '';

  // TODO: Clean this up
  switch (sex) {
    case 'male':
      type = 'man';
      text = 'Male';
      break;
    case 'female':
      type = 'woman';
      text = 'Female';
      break;
    default:
      type = 'user';
      text = 'Unknown Sex';
  }

  return <FactIcon type={type} text={text} />;
};

const FactIcon: FC<{ type?: string; text: string | null | null }> = ({ type, text }: any): JSX.Element => (
  <li>
    <Icon type={type} /> &nbsp; {text}
  </li>
);

interface PatientAddressProps {
  address1: string | null | null;
  address2: string | null | null;
  city: string | null | null;
  state: string | null | null;
  postalCode: string | null | null;
}

const PatientAddress: FC<PatientAddressProps> = ({
  address1 = 'Unknown Street',
  address2 = '',
  city = 'Unknown',
  state = 'Unknown',
  postalCode = '',
}): JSX.Element => {
  return (
    <Row>
      <Col span={2}>
        <Icon type="home" />
      </Col>
      <Col span={12}>
        <ul>
          <li>{address1 || 'Unknown'}</li>
          <li>{address2 || ''}</li>
          <li>{`${city ? `${city},` : ''} ${state || ''} ${postalCode || ''}`}</li>
        </ul>
      </Col>
    </Row>
  );
};

export default SearchHeaderPatientCard;
