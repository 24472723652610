import React, { FC } from 'react';
import { Tag } from 'antd';
import { capitalize } from '../../../helpers';
interface ColorTagInterface {
  status: string;
}

export const ReferralTag: FC<ColorTagInterface> = ({ status }): JSX.Element | null => {
  switch (status) {
    case 'scheduled':
      return <Tag color="blue">{capitalize(status)}</Tag>;
    case 'accepted':
      return <Tag color="yellow">{capitalize(status)}</Tag>;
    case 'pending':
      return <Tag color="orange">{capitalize(status)}</Tag>;
    case 'patient_seen':
      return <Tag color="green">Patient Seen</Tag>;
    case 'rejected':
      return <Tag color="red">Rejected</Tag>;
    case 'failed':
      return <Tag color="red">Failed</Tag>;
    case 'noshow':
      return <Tag color="red">No Show</Tag>;
    case undefined:
    case null:
      return null;
    default:
      return <Tag>{capitalize(status)}</Tag>;
  }
};
