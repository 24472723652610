import React, { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { exchangeCredentials, removeLoginToken, storeLoginToken } from '../../auth';
import { CompleteSignup } from '../components/signup/CompleteSignup';
import { SignupForm, SignupFormData } from '../components/signup/SignupForm';
import { SignupLayout } from './SignupLayout';
import { useBootstrapOrganizationMutation } from '../../../hooks';

interface Props {
  pelitas?: boolean;
}

export const SignupPage: FC<Props> = ({ pelitas }): JSX.Element => {
  const [organizationId, setOrganizationId] = useState<string | undefined>();
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);

  const [bootstrapOrg] = useBootstrapOrganizationMutation();

  const handleSignupFormSubmit = async (signupFormData: SignupFormData): Promise<void> => {
    setStep(2);

    try {
      const result = await bootstrapOrg({
        variables: {
          bootstrapOrganizationInput: {
            name: signupFormData.name,
            email: signupFormData.email,
            password: signupFormData.password,
            organizationName: signupFormData.organizationName,
            isCovid: true,
            isFree: true,
            tags: { covidLead: pelitas ? 'pelitas' : 'blockit' },
          },
        },
      });

      const loginResponse = await exchangeCredentials(signupFormData.email, signupFormData.password);

      setOrganizationId(result.data?.bootstrapOrganization.id);

      if (!loginResponse.err) {
        removeLoginToken();
        storeLoginToken(loginResponse.val);
      }
    } catch (e) {
      setError(true);
    }
  };

  return (
    <SignupLayout pelitas={pelitas}>
      {step === 1 && <SignupForm onSubmit={handleSignupFormSubmit} />}
      {step === 2 && <CompleteSignup signupError={error} onComplete={() => setStep(3)} pelitas={pelitas} />}
      {step === 3 && <Redirect to={`/organizations/${organizationId}/onboarding${pelitas ? '-pelitas' : ''}`} />}
    </SignupLayout>
  );
};
