import { Col, Form, Row, Alert, Input } from 'antd';
import React, { FC, useState, useContext } from 'react';

import { FormComponentProps } from 'antd/lib/form';
import { QuestionBoolean } from './QuestionBoolean';
import { QuestionRadioVertical } from './QuestionRadioVertical';
import { QuestionText } from './QuestionText';
import QuestionUpload from './QuestionUpload';

import { QuestionSelect } from './QuestionSelect';
import { QuestionDate } from './QuestionDate';
import { QuestionTime } from './QuestionTime';
import { QuestionCheckbox } from './QuestionCheckbox';
import { QuestionHTML } from './QuestionHTML';
import { ReferralIdContext } from '../../modules/referrals/components/SearchWizard/components/AddInfoQuestionnaire';

interface Props extends FormComponentProps {
  questions: Question[];
  span: number;
  questionnaireName?: string;
}

export interface Question {
  id: string;
  text: string;
  type: string;
  key?: string | null;
  answerMaxLength?: number | null;
  required: boolean;
  initialValue: string | null;
  options: string[] | null;
  uploadAcceptTypes: string[] | null;
  helpText: string | null;
}

export type ValidateQuestionnaire = (
  rule: { field: string },
  value: string | boolean,
  callback: (arg0?: string) => void
) => void;

export const QuestionnaireFormComponent: FC<Props> = ({ form, questionnaireName, questions, span }): JSX.Element => {
  const [showAlert, setShowAlert] = useState<string[]>([]);
  const referralId = useContext(ReferralIdContext);

  const getAttachmentType = (text: string, helpText: string): string => {
    if (text.includes('authorization') || helpText.includes('authorization')) {
      return 'authorization';
    }

    return 'order';
  };

  const validateQuestionnaire: ValidateQuestionnaire = (rule, value, callback): void => {
    const question = questions.find(q => `questionnaire[0].${q.text.replace(/\./g, ' ')}` === rule.field);

    if (!question) return callback();

    let bookingType = 'appointment';
    if (referralId) {
      bookingType = 'referral';
    }

    if (question.type === 'boolean' && question.required === true && value === true) {
      setShowAlert(v => {
        v.push(question.id);
        return v;
      });
      callback(`This ${bookingType} cannot be scheduled online.`);
    } else if (question.type === 'booleanInverse' && question.required === true && value === false) {
      setShowAlert(v => {
        v.push(question.id);
        return v;
      });
      callback(`This ${bookingType} cannot be scheduled online.`);
    } else if (
      question.type === 'text' &&
      question.answerMaxLength !== undefined &&
      question.answerMaxLength !== null &&
      typeof value === 'string' &&
      value.length > question.answerMaxLength
    ) {
      callback(`should be at most ${question.answerMaxLength} character(s)`);
    } else if (showAlert.indexOf(question.id) !== -1) {
      setShowAlert(v => {
        const idx = v.indexOf(question.id);
        v.splice(idx, 1);
        return v;
      });
    } else {
      callback();
    }
  };

  return (
    <Form>
      <Form.Item>
        {form.getFieldDecorator(`questionnaireName`, {
          initialValue: questionnaireName,
        })(<Input style={{ display: 'none' }} />)}
      </Form.Item>
      <Row gutter={[24, 0]}>
        {showAlert.length !== 0 && !referralId && (
          <Alert
            message="Pre-screening Exception"
            description={
              'We apologize, but this appointment cannot be scheduled online. To schedule an appointment, please call us at the number listed above, under Appointment Details.'
            }
            showIcon
            type="error"
          />
        )}
        {questions.map(question => {
          const answerKey = (
            <Form.Item>
              {form.getFieldDecorator(`questionnaireAnswerKeys[0].${question.text.replace(/\./g, ' ')}`, {
                initialValue: question.key,
              })(<Input style={{ display: 'none' }} />)}
            </Form.Item>
          );

          if (question.type === 'boolean' || question.type === 'booleanInverse')
            return (
              <Col xs={24} md={span} key={question.id}>
                <QuestionBoolean form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );
          if (question.type === 'upload')
            return (
              <Col xs={24} md={span} key={question.id}>
                <Form.Item label={question.text}>
                  {answerKey}
                  <QuestionUpload
                    referralId={referralId}
                    question={question}
                    attachmentType={getAttachmentType(
                      (question.text || '').toLowerCase(),
                      (question.helpText || '').toLowerCase()
                    )}
                    isRequired={question.required}
                    form={form}
                    helpText={question.helpText}
                  />
                </Form.Item>
              </Col>
            );
          if (question.type === 'radio')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionBoolean form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );

          if (question.type === 'radioVertical')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionRadioVertical form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );
          if (question.type === 'text')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionText form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );

          if (question.type === 'select')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionSelect form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );

          if (question.type === 'date')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionDate form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );

          if (question.type === 'time')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionTime form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );

          if (question.type === 'checkbox')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionCheckbox form={form} question={question} validator={validateQuestionnaire} />
              </Col>
            );

          if (question.type === 'html')
            return (
              <Col xs={24} md={span} key={question.id}>
                {answerKey}
                <QuestionHTML question={question} />
              </Col>
            );

          return null;
        })}
      </Row>
    </Form>
  );
};

export const QuestionnaireForm = Form.create<Props>({ name: 'Questionnaire' })(QuestionnaireFormComponent);
