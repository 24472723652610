import { gql } from '@apollo/client';
import _ from 'lodash';
import indexOf from 'lodash/indexOf';
import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { Spinner } from '../../../components';
import { GetAllPayorsAndPlans } from '../../../GraphQL';
import { AppContextConsumer } from '../../core/contexts/AppContext';
import { PayorList } from '../components/PayorList';
import { SelectedPayorList } from '../components/SelectedPayorList';
import { Alert, Row, Col, Card } from 'antd';

const createProfilePlanMutation = gql`
  mutation($profileId: ID!, $planId: ID!) {
    createProfilePlan(profileId: $profileId, planId: $planId) {
      profileId
      payorPlanId
    }
  }
`;

const deleteProfilePlanMutation = gql`
  mutation($profileId: ID!, $planId: ID!) {
    deleteProfilePlan(profileId: $profileId, planId: $planId) {
      profileId
      payorPlanId
    }
  }
`;

const getPayorsAndPlansByProfileQuery = gql`
  query($profileId: ID!) {
    getPayorsAndPlansByProfile(profileId: $profileId) {
      profileId
      payorPlanId
      payorPlan {
        id
        name
        payor {
          id
          name
        }
      }
    }
  }
`;

class InsuranceManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: props.id,
      selectedPayorId: null,
      selectedPayorsAndPlans: [],
      profilePlansInitialized: false,
      requestInProgess: false,
    };
  }

  handlePayorSelected = payorId => {
    if (this.state.selectedPayorId === payorId) {
      this.setState({ selectedPayorId: null });
    } else {
      this.setState({ selectedPayorId: payorId });
    }
  };

  handlePlanRemove = (profileId, plan) => {
    const statePayorsPlans = this.state.selectedPayorsAndPlans;
    const payors = statePayorsPlans.map(p => p.name);
    const payorIndex = indexOf(payors, plan.payor.name);

    // make sure it exists before we delete
    if (payorIndex > -1) {
      const plans = statePayorsPlans[payorIndex].plans.map(p => p.name);
      const planIndex = indexOf(plans, plan.name);
      if (planIndex > -1) {
        // api call to delete plan from profile
        this.props
          .deleteProfilePlan(profileId, plan.id)
          .then(res => {
            statePayorsPlans[payorIndex].plans.splice(planIndex, 1);

            // if payors has no more plans...remove the payor from the array
            if (statePayorsPlans[payorIndex].plans.length === 0) {
              statePayorsPlans.splice(payorIndex, 1);
            }

            this.setState({ selectedPayorsAndPlans: statePayorsPlans });
          })
          .catch(e => console.error('ERROR: ', e));
      }
    }
  };

  executePlanAdd = (profileId, payorAndPlan, isInitialLoad) => {
    // Plans = this.state.selectedPayorsAndPlans;
    const extendedPayorAndPlan = {
      ...payorAndPlan,
      plan: { ...payorAndPlan.plan, payor: payorAndPlan.payor },
    };

    const statePayorsPlans = this.state.selectedPayorsAndPlans;
    const payors = statePayorsPlans.map(p => p.name);
    const payorIndex = indexOf(payors, payorAndPlan.payor.name);

    // if the payor exists, don't add it
    if (payorIndex > -1) {
      const plans = statePayorsPlans[payorIndex].plans.map(p => p.name);
      // if the plan doesn't exist, add it
      if (indexOf(plans, payorAndPlan.plan.name) === -1) {
        if (isInitialLoad) {
          statePayorsPlans[payorIndex].plans.push({
            ...payorAndPlan.plan,
            payor: payorAndPlan.payor,
          });
          this.setState({ selectedPayorsAndPlans: statePayorsPlans });
        } else {
          this.setState({ requestInProgess: true });
          this.props
            .createProfilePlan(profileId, extendedPayorAndPlan.plan.id)
            .then(res => {
              statePayorsPlans[payorIndex].plans.push({
                ...payorAndPlan.plan,
                payor: payorAndPlan.payor,
              });
              this.setState({ selectedPayorsAndPlans: statePayorsPlans });
              this.setState({ requestInProgess: false });
            })
            .catch(e => console.error('ERROR: ', e));
        }
      }
    } else if (isInitialLoad) {
      // add the payor since it doesn't exist
      statePayorsPlans.push({
        name: payorAndPlan.payor.name,
        plans: [{ ...payorAndPlan.plan, payor: payorAndPlan.payor }],
      });
      this.setState({ selectedPayorsAndPlans: statePayorsPlans });
    } else {
      this.setState({ requestInProgess: true });
      this.props
        .createProfilePlan(profileId, extendedPayorAndPlan.plan.id)
        .then(res => {
          // add the payor since it doesn't exist
          statePayorsPlans.push({
            name: payorAndPlan.payor.name,
            plans: [{ ...payorAndPlan.plan, payor: payorAndPlan.payor }],
          });
          this.setState({ selectedPayorsAndPlans: statePayorsPlans });
          this.setState({ requestInProgess: false });
        })
        .catch(e => console.error('ERROR: ', e));
    }
  };

  initialAddPlans = profilePlans => {
    if (this.state.profilePlansInitialized) {
      return;
    }
    this.setState({ profilePlansInitialized: true });

    const formattedPlans = profilePlans.map(p => ({
      plan: p.payorPlan,
      payor: p.payorPlan.payor,
    }));
    formattedPlans.forEach(p => this.executePlanAdd(null, p, true));
  };

  handlePlanAdd = (profileId, payorAndPlan) => {
    this.executePlanAdd(profileId, payorAndPlan, false);
  };

  render() {
    return (
      <AppContextConsumer>
        {({ currentOrganization }) => {
          if (!currentOrganization) return null;

          return (
            <Query query={getPayorsAndPlansByProfileQuery} variables={{ profileId: this.state.profileId }}>
              {({ loading, error, data }) => {
                if (loading) return <Spinner />;
                if (error) return error;

                const {
                  allPayorsAndPlans: { loading: allPayorsAndPlansLoading, getAllPayorsAndPlans: payorsAndPlans },
                } = this.props;

                // check if we need to add our profilePlans to our list
                // FIXME: Can't call setState in render()
                if (!loading && !this.profilePlansInitialized) {
                  this.initialAddPlans(data.getPayorsAndPlansByProfile);
                }

                return (
                  <>
                    <Row gutter={12}>
                      <Col span={10}>
                        <Card title="Available Plans">
                          {allPayorsAndPlansLoading ? (
                            <Spinner />
                          ) : (
                            <PayorList
                              payors={payorsAndPlans}
                              handlePayorSelected={this.handlePayorSelected}
                              selectedPayorId={this.state.selectedPayorId}
                              handlePlanAdd={payorAndPlan => this.handlePlanAdd(this.state.profileId, payorAndPlan)}
                            />
                          )}
                        </Card>
                      </Col>
                      <Col span={10}>
                        <Card title="Selected Plans">
                          {loading || this.state.requestInProgess ? (
                            <Spinner />
                          ) : (
                            <SelectedPayorList
                              selectedPayorsAndPlans={this.state.selectedPayorsAndPlans}
                              handlePlanRemove={plan => this.handlePlanRemove(this.state.profileId, plan)}
                            />
                          )}
                        </Card>
                      </Col>
                      <Col span={4}>
                        <Alert
                          message="Insurance"
                          description={
                            <p>
                              Use this page to manage insurance payors and plans acceepted by your profile. To get
                              started, click the name of a payor and click one of the plans to add it to your accepted
                              list.
                            </p>
                          }
                          type="info"
                        />
                      </Col>
                    </Row>
                  </>
                );
              }}
            </Query>
          );
        }}
      </AppContextConsumer>
    );
  }
}

export default _.flowRight(
  graphql(deleteProfilePlanMutation, {
    props: ({ mutate }) => ({
      deleteProfilePlan: (profileId, planId) => mutate({ variables: { profileId, planId } }),
    }),
  }),
  graphql(createProfilePlanMutation, {
    props: ({ mutate }) => ({
      createProfilePlan: (profileId, planId) => mutate({ variables: { profileId, planId } }),
    }),
  }),
  graphql(GetAllPayorsAndPlans, {
    name: 'allPayorsAndPlans',
  })
)(InsuranceManagement);
