import { Button, Card } from 'antd';
import React, { FC, useState, useEffect } from 'react';
import { AppointmentDetailsDisplayType, AppointmentsDetailsCard } from './AppointmentsDetailsCard';
import { ChatCard } from './ChatCard';
import { ReferralData } from './GetReferralQuery';
import { ReferralDetailsCard } from './ReferralDetailsCard';
import { AuditLogCard } from './AuditLogCard';

export const ReferralTabCard: FC<{ referral: ReferralData }> = ({ referral }): JSX.Element => {
  const [tabKey, setTabKey] = useState('referral');
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setIsPrinting(true); // Set isPrinting to true
  };

  useEffect(() => {
    if (isPrinting) {
      window.print();
      setIsPrinting(false); // Reset isPrinting after printing
    }
  }, [isPrinting]);

  const printButtonText =
    tabKey === 'referral'
      ? 'Print Referral Details'
      : tabKey === 'appointments'
      ? 'Print Appointment Details'
      : 'Print';

  let adjustedTabList;

  const baseTabList = [
    { key: 'referral', tab: 'Referral' },
    { key: 'appointments', tab: 'Appointments' },
    { key: 'chat', tab: 'Chat' },
    { key: 'auditLogs', tab: 'Referral Status Logs' },
  ];

  const printButtonTab = {
    key: 'print',
    tab: (
      <Button onClick={e => handlePrint(e)} type="primary" icon="printer">
        {printButtonText}
      </Button>
    ),
  };

  const initialTabList =
    tabKey === 'referral' || tabKey === 'appointments' ? [...baseTabList, printButtonTab] : baseTabList;

  if (isPrinting) {
    const activeTab = initialTabList.find(tab => tab.key === tabKey);
    adjustedTabList = activeTab ? [activeTab] : initialTabList;
  } else {
    adjustedTabList = initialTabList;
  }

  const tabComponents: {
    [key: string]: JSX.Element;
  } = {
    referral: <ReferralDetailsCard referral={referral} />,
    appointments: (
      <AppointmentsDetailsCard
        referral={referral}
        appointment={referral.appointment}
        cardType={AppointmentDetailsDisplayType.Referral}
      />
    ),
    chat: <ChatCard referral={referral} />,
    auditLogs: <AuditLogCard referral={referral} />,
  };
  return (
    <>
      <div className="referral-card-container">
        <style>{`
        .referral-card-container .ant-tabs-nav-animated .ant-tabs-tab:last-child {
            padding-left: 9%; /* adjust this value to your preference */
        }
    `}</style>

        <Card
          style={{ width: '100%' }}
          tabList={adjustedTabList}
          activeTabKey={tabKey}
          onTabChange={key => {
            setTabKey(key);
          }}
        >
          {isPrinting ? (
            <div className="tab-content-active">{tabComponents[tabKey]}</div>
          ) : (
            <div className={`tab-content ${tabKey}`}>{tabComponents[tabKey]}</div>
          )}
        </Card>
      </div>
    </>
  );
};
