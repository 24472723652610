import * as Sentry from '@sentry/browser';
import { gql } from '@apollo/client';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Button, message, Tag, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { PatientData, PatientReferral } from './GetReferralQuery';

interface Data {
  updatePatientReferral: {
    id: string;
  };
}

interface Props {
  patient: PatientData;
  patientReferral: PatientReferral;
  hasAppointment: boolean;
}

interface Variables {
  id: string;
  updatePatientReferralInput: {};
}

const { Text } = Typography;

const updatePatientReferralMutation = gql`
  mutation UpdatePatientReferral($id: ID!, $updatePatientReferralInput: UpdatePatientReferralInput!) {
    updatePatientReferral(id: $id, updatePatientReferralInput: $updatePatientReferralInput) {
      id
    }
  }
`;

const Styles = styled.div`
  .email-text {
    font-size: 0.75rem;
  }

  .resend {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    margin-top: 4px;
  }
`;

export const SentToPatient: FC<Props> = ({ patient, patientReferral, hasAppointment }): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const [updatePatientReferral] = useMutation<Data, Variables>(updatePatientReferralMutation, {
    onCompleted: () => {
      setLoading(false);
      message.success(`Successfully resent referral to patient.`);
    },
    onError: error => {
      setLoading(false);
      message.error('There was an error resending this referral to the patient.  Please try again in a few minutes');
      Sentry.captureException(error);
    },
    variables: {
      id: patientReferral.id,
      updatePatientReferralInput: {},
    },
  });

  const handleResendToPatient = (): void => {
    setLoading(true);
    updatePatientReferral().then(() => {});
  };

  return (
    <Styles>
      <div className="resend">
        <Tag color="green">Sent To Patient</Tag>
        {hasAppointment || !patientReferral.profile ? null : (
          <Button size="small" type="primary" loading={loading} onClick={handleResendToPatient}>
            Resend
          </Button>
        )}
      </div>
      <div className="email-text">
        <Text>{patientReferral.patientEmail || patient.email}</Text>
      </div>
    </Styles>
  );
};
