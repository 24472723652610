import { gql } from '@apollo/client';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { Profile } from './useGetConsumerSchedulingProfileQuery';
import { ProcedureInterface } from '../../../GraphQL/ListReferrals.graphql';
import { QuestionnaireAnswerSet } from '../../../generated/graphql';

const createConsumerSchedulingEngagementMutation = gql`
  mutation CreateConsumerSchedulingEngagementMutation(
    $organizationId: ID!
    $createConsumerSchedulingEngagementInput: CreateConsumerSchedulingEngagementInput
    $createConsumerSchedulingEngagementInstantInput: CreateConsumerSchedulingEngagementInstantInput
  ) {
    createConsumerSchedulingEngagement(
      organizationId: $organizationId
      createConsumerSchedulingEngagementInput: $createConsumerSchedulingEngagementInput
      createConsumerSchedulingEngagementInstantInput: $createConsumerSchedulingEngagementInstantInput
    ) {
      id
      start
      end
      status
      procedure {
        id
        display
        name
        specialty {
          id
          name
        }
      }
      profile {
        id
        displayName
        prefix
        firstName
        LastName
        suffix
        phone
        email
        profileImgUrl
        professionalStatement
        boardCertifications
        educationTraining
        npiNumber
        specialty
        generalPatientInstructions
        organization {
          id
          name
        }
        location {
          id
          name
          address1
          address2
          city
          state
          postalCode
          country
          distanceFromPatient
          distanceFromProvider
          latitude
          longitude
          phone
          timeZone
        }
        isIntegrated
        isSendToProvider
        isWaitList
      }
    }
  }
`;

export interface Engagement {
  id: string;
  start: string;
  end: string;
  slotIdsForAppointment: string[];
  procedure: ProcedureInterface;
  profile: Profile;
  status: string;
}

interface CreateConsumerSchedulingEngagementInput {
  patientId: string;
  procedureId: string;
  profileId: string;
  slotIds: string[];
  start: string;
  end: string;
  comment?: string;
  order?: string;
  tags?: {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
  };
  appointmentAttachments?: File[];
  questionnaireAnswerSets?: QuestionnaireAnswerSet[];
}

interface CreateConsumerSchedulingEngagementInstantInput {
  patientId: string;
  procedureId: string;
  profileId: string;
  comment?: string;
  order?: string;
}

export interface CreateConsumerSchedulingEngagementMutationData {
  createConsumerSchedulingEngagement: Engagement;
}

interface Variables {
  organizationId: string;
  createConsumerSchedulingEngagementInput?: CreateConsumerSchedulingEngagementInput;
  createConsumerSchedulingEngagementInstantInput?: CreateConsumerSchedulingEngagementInstantInput;
}

type Options = MutationHookOptions<CreateConsumerSchedulingEngagementMutationData, Variables>;

type Result = MutationTuple<CreateConsumerSchedulingEngagementMutationData, Variables>;

export const useCreateConsumerSchedulingEngagementMutation = (options: Options): Result => {
  return useMutation<CreateConsumerSchedulingEngagementMutationData, Variables>(
    createConsumerSchedulingEngagementMutation,
    options
  );
};
