import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { AppContextConsumer } from '../../contexts/AppContext';
import { IsOrgAdminOrBlockitAdmin } from '../../../../components/IsOrgAdminOrBlockitAdmin';
import { FeatureFlag, FeatureGate } from '../../../../helpers';
import { ViewerOrganization } from '../../../viewer';
import config from '../../../../config';

interface NavProps {
  organization: ViewerOrganization | null;
  mode: string;
}

const Styles = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-top: 1.8rem;

  a,
  a:visited,
  .is-super-user {
    border-bottom: 5px solid transparent;
    color: #fff;
    display: block;
    font-size: 20px;
    padding-bottom: 15px;
    text-decoration: none;
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }

    &.active,
    &:hover {
      border-bottom-color: #fff;
    }

    &.active {
      font-weight: 700;
    }
  }
`;

const ReferralsNav: FC<NavProps> = ({ organization }): JSX.Element | null =>
  organization && (
    <>
      <FeatureGate feature={FeatureFlag.HasSchedulePatient}>
        <NavLink to={`/referrals/new`} exact>
          Schedule a Patient
        </NavLink>
      </FeatureGate>
      <NavLink to={`/organizations/${organization.id}/referrals`} exact>
        Referrals
      </NavLink>
      <FeatureGate feature={FeatureFlag.ConsumerScheduling}>
        <NavLink to={`/organizations/${organization.id}/appointments`} exact>
          Appointments
        </NavLink>
      </FeatureGate>
      <FeatureGate feature={FeatureFlag.HasProviderDirectory}>
        <NavLink to={`/directory`} exact>
          Provider Directory
        </NavLink>
      </FeatureGate>
      <FeatureGate feature={FeatureFlag.HasPatientDirectory}>
        <a href={`${config.endpoint}/patient-directory?organization_id=${organization.id}`}>Patient Directory</a>
      </FeatureGate>
      <FeatureGate feature={FeatureFlag.HasPatientItinerary}>
        <a href={`${config.endpoint}/itineraries?organization_id=${organization.id}`}>View Itineraries</a>
      </FeatureGate>
      <FeatureGate
        feature={FeatureFlag.WorkflowQueuesV2}
        fallbackContent={
          <NavLink to={`/organizations/${organization.id}/workflows`} exact>
            Workflow Queues
          </NavLink>
        }
      >
        <a href={`${config.endpoint}/workflows?organization_id=${organization.id}`}>Workflow Queues</a>
      </FeatureGate>
      <IsOrgAdminOrBlockitAdmin>
        <FeatureGate feature={FeatureFlag.DataAnalytics}>
          <a href={`${config.endpoint}/data-analytics/referral/organization?organization_id=${organization.id}`}>
            Dashboard
          </a>
        </FeatureGate>
      </IsOrgAdminOrBlockitAdmin>
    </>
  );

const AdminNav: FC<NavProps> = ({ organization }): JSX.Element | null =>
  organization && (
    <>
      <IsOrgAdminOrBlockitAdmin>
        <NavLink to={`/organizations/${organization.id}/organization-settings`} exact>
          Organization
        </NavLink>
      </IsOrgAdminOrBlockitAdmin>
      <NavLink to={`/organizations/${organization.id}/profiles`}>Profiles</NavLink>
      <NavLink to={`/organizations/${organization.id}/providers`}>Providers</NavLink>
      <NavLink to={`/organizations/${organization.id}/users`}>Users</NavLink>
      <NavLink to={`/organizations/${organization.id}/locations`}>Location</NavLink>
      <FeatureGate feature={FeatureFlag.HasNetworkGroups}>
        <NavLink to={`/organizations/${organization.id}/groups`}>Network Groups</NavLink>
      </FeatureGate>
      <FeatureGate feature={FeatureFlag.ProviderManagement}>
        <a href={`${config.endpoint}/provider-management?organization_id=${organization.id}`}>Provider Management</a>
      </FeatureGate>
      <FeatureGate feature={FeatureFlag.ConsumerScheduling}>
        <a href={`${config.endpoint}/organizations/${organization.id}/directories`}>Directories</a>
      </FeatureGate>
    </>
  );

export const AppNav = (): JSX.Element => (
  <AppContextConsumer>
    {({ currentOrganization, mode }) => (
      <Styles>
        {mode === 'referral' ? (
          <ReferralsNav organization={currentOrganization} mode={'referral'} />
        ) : (
          <AdminNav organization={currentOrganization} mode={'admin'} />
        )}
      </Styles>
    )}
  </AppContextConsumer>
);
