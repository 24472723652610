import { Col, Row, Skeleton } from 'antd';
import { gql } from '@apollo/client';
import moment from 'moment';
import React, { FC } from 'react';
import { Query } from '@apollo/client/react/components';
import styled from 'styled-components';
import { DatesBar } from './DatesBar';
import { TimeSlots } from './TimeSlots';
import { useViewSlotsContainerContext } from './ViewSlotsContainer';

const Styles = styled.div`
  .row-wrapper.ant-row-flex {
    padding-top: 0;
  }

  .dates-bar {
    margin-bottom: 1em;
  }

  .time-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    &.dash-btn {
      border: 0;
      font-weight: bold;
      box-shadow: none;
    }
  }
`;

const DATE_FORMAT = 'MM/DD/YYYY';

const GET_OWN_SLOTS_QUERY = gql`
  query GetOwnSlots(
    $profileId: ID!
    $procedureId: ID!
    $start: String
    $patientIsPreAuthorized: Boolean
    $searchType: SearchType
  ) {
    GetOwnSlots(
      profileId: $profileId
      procedureId: $procedureId
      start: $start
      patientIsPreAuthorized: $patientIsPreAuthorized
      originator: "get_own_slots_query"
      searchType: $searchType
    ) {
      id
      status
      start
      end
      slotIdsForAppointment
      serviceType {
        coding {
          code
          display
          system
          version
        }
      }
    }
  }
`;

interface CodingInterface {
  system: string;
  version: string;
  code: string;
  display: string;
  userSelected: boolean;
}

interface CodeableConceptInterface {
  text: string;
  coding: CodingInterface[];
}

export interface SlotsInterface {
  id: string;
  status: string;
  start: string;
  end: string;
  slotIdsForAppointment: string[];
  serviceType: CodeableConceptInterface[];
}

interface Data {
  GetOwnSlots: SlotsInterface[];
}

export enum SearchType {
  All = 'ALL',
  Consumer = 'CONSUMER',
  Referral = 'REFERRAL',
}

interface Props {
  profileId: string;
  procedureId: string;
  searchType: SearchType;
}

const computeDaysFromSlots = (slots: SlotsInterface[]): {} => {
  const sortedSlots = slots.slice().sort((a, b) => {
    if (a.start < b.start) {
      return -1;
    }

    if (a.start > b.start) {
      return 1;
    }

    return 0;
  });

  return sortedSlots.reduce<{ [key: string]: (string | SlotsInterface)[] }>((acc, slot) => {
    const date = moment(slot.start).format(DATE_FORMAT);
    const ids = `__ids__${date}`;

    if (!(date in acc)) {
      acc[date] = [];
      acc[ids] = [];
    }

    if (acc[ids].indexOf(slot.id) === -1) {
      acc[date].push(slot);
      acc[ids].push(slot.id);
    }

    return acc;
  }, {});
};

export const SlotsContainer: FC<Props> = ({ profileId, procedureId, searchType }): JSX.Element => {
  const { dates, arrowsColSpan, slotsColSpan } = useViewSlotsContainerContext();

  return (
    <div>
      <Query<Data, Props & { start: string }>
        query={GET_OWN_SLOTS_QUERY}
        fetchPolicy="no-cache"
        variables={{
          profileId: profileId,
          procedureId: procedureId,
          start: dates[0].toISOString(),
          searchType: searchType,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Skeleton active />;
          if (error) return <div>Error</div>;

          const getOwnSlots = data && data.GetOwnSlots ? data.GetOwnSlots : [];
          const days: {
            [key: string]: SlotsInterface[];
          } = computeDaysFromSlots(getOwnSlots);
          const formattedDates = dates.map((d: moment.Moment) => d.format(DATE_FORMAT));

          return (
            <Styles>
              <Row className="dates-bar">
                <Col span={24}>
                  <DatesBar />
                </Col>
              </Row>
              <Row gutter={16} type="flex" justify="center" align="top" className="row-wrapper">
                <Col span={arrowsColSpan} className="left-arrow" />
                {formattedDates.map((date: string) => {
                  const times = days[date] || [];

                  return (
                    <Col key={date} span={slotsColSpan} className="date-wrapper">
                      <TimeSlots times={times} />
                    </Col>
                  );
                })}
                <Col span={arrowsColSpan} className="right-arrow" />
              </Row>
            </Styles>
          );
        }}
      </Query>
    </div>
  );
};
