import { Button } from 'antd';
import _ from 'lodash';
import * as moment from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { SlotsInterface } from './SlotsContainer';
import { useViewSlotsContainerContext } from './ViewSlotsContainer';

const Styles = styled.div`
  .dash-btn {
    cursor: auto;
  }

  .time-btn {
    color: black;
    font-weight: 800;
    :hover: {
      cursor: auto;
    }
  }
`;

const EmptyButton: FC<{}> = (): JSX.Element => (
  <Button block className="time-btn dash-btn">
    —
  </Button>
);

interface Props {
  times: SlotsInterface[];
}

export const TimeSlots: FC<Props> = ({ times }): JSX.Element => {
  const { maxTimes, setMaxTimes } = useViewSlotsContainerContext();
  const [showMore, setShowMore] = useState(false);

  return (
    <Styles>
      {_.fill(Array(maxTimes), 0).map((_item, index) => {
        if (times[index]) {
          return (
            <Button block type="link" key={index} className="time-btn">
              {moment.default(times[index].start).format('h:mma')}
            </Button>
          );
        } else {
          return <EmptyButton key={index} />;
        }
      })}
      {times.length > maxTimes && !showMore ? (
        <Button
          block
          type="link"
          key="show-more"
          className="time-btn"
          onClick={() => {
            setShowMore(true);
            setMaxTimes(times.length);
          }}
        >
          Show More
        </Button>
      ) : null}
    </Styles>
  );
};
