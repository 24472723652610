import { Form, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { gql } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
const Option = Select.Option;

const searchPatientsQuery = gql`
  query($filter: String!, $organizationId: ID!) {
    searchPatients(filter: $filter, organizationId: $organizationId) {
      id
      identifier
      givenName1
      givenName2
      givenName3
      familyName
      dob
      sex
      address1
      address2
      city
      state
      postalCode
      phone
      email
    }
  }
`;

interface Patient {
  id: string;
  identifier?: string;
  givenName1?: string;
  givenName2?: string;
  givenName3?: string;
  familyName?: string;
  dob?: string;
  sex?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  email?: string;
}

interface Data {
  searchPatients: Patient[];
}

interface Variables {
  filter: string;
  organizationId: string;
}

interface Props {
  handleClearNewPatient: () => void;
  newPatient?: Patient;
  form: WrappedFormUtils;
  organization: {
    id: string;
  };
  style?: React.CSSProperties;
  onChange?: (arg0: Patient) => void;
  preselectedPatient?: string;
}

interface State {
  options: JSX.Element[];
  patients: Patient[];
}

class PatientSearchField extends React.Component<WithApolloClient<Props>, State> {
  readonly state: State = {
    options: [],
    patients: [],
  };

  createPatientOption = (patient: Patient): JSX.Element => {
    const dob = moment(patient.dob).format('MM/DD/YYYY');
    return (
      <Option key={patient.id}>
        <div>
          {`${patient.givenName1} ${patient.familyName}`}
          <span style={{ fontSize: 12, paddingLeft: 8 }}>{dob}</span>
          {patient.identifier ? (
            <span style={{ fontSize: 12, color: '#aaa', float: 'right' }}>
              &nbsp;MRN: {patient.identifier.slice(0, 15)}
            </span>
          ) : null}
        </div>
      </Option>
    );
  };

  handleSearch = async (patientName: string): Promise<void> => {
    this.props.handleClearNewPatient();
    if (patientName && patientName.length > 2) {
      if (this.props.client) {
        const {
          data: { searchPatients: patients },
        } = await this.props.client.query<Data, Variables>({
          query: searchPatientsQuery,
          variables: { filter: patientName, organizationId: this.props.organization.id },
        });

        const options: JSX.Element[] = [];
        patients.forEach(patient => {
          options.push(this.createPatientOption(patient));
        });

        this.setState({
          options,
          patients,
        });
      }
    } else {
      this.setState({ options: [], patients: [] });
    }
  };

  debounceHandleSearch = _.debounce(this.handleSearch, 300);

  render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;
    let options;
    if (this.props.newPatient) {
      options = this.createPatientOption(this.props.newPatient);
    } else {
      options = this.state.options;
    }

    return (
      <Form.Item label="What's the patient's name?" help="Type at least 3 letters to search">
        {getFieldDecorator('patientSearchField', {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        })(
          <Select
            showSearch
            placeholder="Patient Name"
            style={this.props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.debounceHandleSearch}
            onChange={value => {
              const patient = this.state.patients.find(p => {
                return p?.id === value;
              });

              if (patient && this.props.onChange) {
                this.props.onChange(patient);
              }
            }}
            notFoundContent={null}
          >
            {options}
          </Select>
        )}
      </Form.Item>
    );
  }
}

export default withApollo<Props>(PatientSearchField);
