import { Tag } from 'antd';
import React, { FC } from 'react';

interface Props {
  profile?: {
    isAccessCenter: boolean;
  };
  status?: string;
}

export const SentToProviderTag: FC<Props> = ({ profile, status }): JSX.Element => {
  if (profile?.isAccessCenter) {
    return <Tag color="green">Sent To Access Center</Tag>;
  }

  if (status === 'cancelled') {
    return <Tag>Cancelled</Tag>;
  }

  return <Tag color="green">Sent To Provider</Tag>;
};
